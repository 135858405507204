import { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

import "venobox/dist/venobox.min.css";

import bannerImg from "../../public/images/banner/banner-image-03.jpg";
import separator from "../../public/images/separator/separator-top.svg";

const Home = () => {
  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });
  }, []);
  return (
    <>
      <section id="hero"
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="inner text-center mt--60">
                <h1 className="title display-one">
                  SecurX <br />
                  {/*Empowering Your <br />*/}
                  <span className="theme-gradient">Empowering</span>Your <br /> Security Shield
                  {/*{" "}*/}
                  {/*<br />*/}
                  {/*<span className="color-off">SecurX</span>*/}
                </h1>

                <p className="b1 desc-text pb-4">
                  Next-Gen Penetration Testing Solutions
                </p>

              </div>
            </div>
            <div className="col-lg-10 col-xl-10 order-1 order-lg-2">
              <div style={{ height: "100px" }}></div>
            </div>
          </div>
        </div>
        <div className="chatenai-separator has-position-bottom">
          <Image className="w-100" src={separator} alt="" />
        </div>
      </section>
    </>
  );
};

export default Home;
