import Image from "next/image";

import splitImg from "../../public/images/split/split-8.png";
import Link from "next/link";

const Split = () => {
  return (
    <>
      <section className="rainbow-split-area rainbow-section-gap" id="about">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      About Us
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >

                    </p>
                    <ul
                      className="split-list"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="350"
                    >
                      <p
                      >
                        SecurX is a leading provider of cybersecurity
                        services, specializing in comprehensive penetration
                        testing solutions. <br /> We employ cutting-edge techniques to
                        identify vulnerabilities and assess network resilience,
                        ensuring your organization remains secure against
                        evolving cyber threats. Our tailored approach delivers
                        detailed insights and actionable recommendations, aimed
                        at fortifying your defenses and maintaining the
                        integrity of your network.<br /> With a commitment to
                        excellence and customer satisfaction, SecurX is your
                        trusted partner in cybersecurity.
                      </p>
                    </ul>
                    <div
                      className="view-more-button mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    ></div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={splitImg}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default Split;
