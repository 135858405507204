import Image from "next/image";

import splitImg from "../../public/images/split/Offensive-security-stuff 1 1.png";
import Link from "next/link";

const Split = () => {
  return (
    <>
      <section id="split" className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={splitImg}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      What makes us different
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      At SecurX, we stand out in the penetration testing industry by combining the expertise of intelligent minds with the power of advanced automation. Here’s what sets us apart:
                    </p>
                    <ul
                      className="split-list"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="350"
                    >
                      <h5>Comprehensive Threat Simulation</h5>
                      <li>Our team uses advanced simulation techniques to mimic real-world cyber threats, providing a thorough analysis of your network's vulnerabilities and resilience.</li>

                      <h5>Customized Testing Solutions</h5>
                      <li>We tailor our penetration testing services to meet the specific needs and complexities of your organization, ensuring that all potential weak points are addressed effectively.</li>

                      <h5>Expert Insights and Recommendations</h5>
                      <li> Our detailed reports not only identify vulnerabilities but also offer actionable recommendations and strategic guidance to strengthen your network's defenses and improve overall security posture.</li>
                    </ul>
                    <div
                      className="view-more-button mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    >
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Split;
