import React, { useEffect } from "react";
import sal from "sal.js";

import PricingData from "../../data/home.json";
import Link from "next/link";

const Pricing = () => {
  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);
  return (
    <>
      <section className="rainbow-pricing-area rainbow-section-gap" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="150"
              >
                <h4 className="subtitle ">
                  <span className="theme-gradient">Pricing</span>
                </h4>
                <h2 className="title w-600 mb--20">
                  Transparent, simple, and affordable pricing model.
                </h2>
                <p className="description b1">
                  Our pricing plans are clear and transparent, without relying on complex testing credits or limited penetration testing hours.                </p>
              </div>
            </div>
          </div>
          <div className="col">
            {PricingData &&
              PricingData.pricing.slice(0, 2).map((data, index) => (
                <div className="col-lg-12 col-md-12 col-12" key={index}>
                  <div
                    className={`rainbow-pricing style-2 ${data.isActive ? "active" : ""
                      }`}
                  >
                    <div className="pricing-table-inner bg-flashlight">
                      <div className="pricing-header">
                        <div className="pricing">
                          <div className="price-wrapper">
                            <span className="price">
                              {data.title}
                            </span>
                          </div>
                          <h4 className="price">{data.subTitle}</h4>
                          <span className="subtitle">{data.text}</span>
                        </div>
                      </div>
                      <div className="separator-animated animated-true mt--30 mb--30"></div>
                      <div className="pricing-body">
                        <ul className="list-style--1">
                          {data.subItem.map((innerData, innerIndex) => (
                            <li key={innerIndex}>
                              <i
                                className={`feather-${innerData.isMinus ? "minus" : "check"
                                  }-circle pe-2`}
                              ></i>
                              {innerData.text}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="pricing-footer">
                        {data.price === 0 ? (
                            <Link
                                className={`btn-default ${!data.isActive ? "btn-border" : ""}`}
                                href="/contact?plan=basic"
                            >                            Contact us
                          </Link>
                        ) : (
                          <Link
                            className={`btn-default ${!data.isActive ? "btn-border" : ""}`}
                            href="/contact?plan=premium"
                          >
                            Get A Quote
                          </Link>
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
