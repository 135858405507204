import Image from "next/image";
import image1 from "../../public/images/brand/OSCP.D0Wrgjqy_2lOY92.png";
import image2 from "../../public/images/brand/OSWE.DI54Eyvq_Zo5ESJ.png";
import image3 from "../../public/images/brand/Frame 5.png";
import image4 from "../../public/images/brand/OSEP.D_R8bS1A_Z1JYK2E (1) 1.png";
// import { useCollection } from "react-firebase-hooks/firestore";
// import firebase from "../../Firebase/firebase";




const Brands = () => {
  // const db = firebase.firestore();
  //
  // const [votes, votesLoading, votesError] = useCollection(
  //     firebase.firestore().collection("quotes"),
  //     {}
  // );
  //
  // const [name, setName] = useState('ezrzer');
  // const [email, setEmail] = useState('zerze');
  // const [message, setMessage] = useState('zerzer');
  //
  // const submitForm = async () => {
  //   // e.preventDefault();
  //
  //   try {
  //     await db.collection('quotes').doc().set({
  //       name,
  //       email,
  //       message,
  //       timestamp: new Date()
  //     });
  //     alert('Form submitted successfully!');
  //     setName('');
  //     setEmail('');
  //     setMessage('');
  //   } catch (error) {
  //     console.error('Error submitting form: ', error);
  //     alert('There was an error submitting the form. Please try again.');
  //   }
  // };
 
  return (
      <>
        <section id="certifications" className="rainbow-brand-area rainbow-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                    className="section-title text-center sal-animate"
                    data-sal="slide-up"
                    data-sal-duration="2500"
                    data-sal-delay="100"
                >
                  <h4 className="subtitle ">
                    <span className="theme-gradient">Our Certifications</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 mt-10">
                <ul className="list-inline d-flex justify-content-center">
                  <li className="list-inline-item m-4">
                    <a href="#">
                      <Image
                          src={image1}
                          width={250}
                          height={250}
                          alt="Brand Image"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item m-4">
                    <a href="#" >
                      <Image
                          src={image2}
                          width={250}
                          height={250}
                          alt="Brand Image"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item m-4">
                    <a href="#">
                      <Image
                          src={image4}
                          width={250}
                          height={250}
                          alt="Brand Image"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item m-4">
                    <a href="#">
                      <Image
                          src={image3}
                          width={250}
                          height={250}
                          alt="Brand Image"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default Brands;
