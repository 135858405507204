import React, { useEffect } from "react";

import sal from "sal.js";

import ServiceItem from "./ServiceItem";
import ServiceData from "../../data/home.json";

const Service = () => {
  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);
  return (
    <>
      <section className="rainbow-service-area rainbow-section-gap" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                  className="section-title text-center"
                  data-sal="slide-up"
                  data-sal-duration="700"
                  data-sal-delay="100"
              >
                <h4 className="subtitle">
                  <span className="theme-gradient">See Our Services</span>
                </h4>
                <h2 className="title w-600 mb--20">
                  We don't just scan, We innovate
                </h2>
                <p className="b1 desc-text">
                  Highly specialized in pentest, SecurX helps you increase your cybersecurity with security audits of
                  the following scope: web platforms, mobile apps, infrastructure.
                </p>
              </div>
            </div>
          </div>

          <div className="row row--15 service-wrapper">
            <ServiceItem ServiceData={ServiceData}/>
          </div>
          <p className="b1 desc-text text-center pt--35">
            Our security audit reports provide a lot of details that enable a developer or an adminsys to easily understand and correct the vulnerabilities. We can also provide a non-technical executive summary to communicate to your board and partners.
          </p>
        </div>
      </section>
    </>
  );
};

export default Service;
